// E-CONTAINER - CONTAINER
.e-grid-container , 
.e-container,
.grid-container , 
.container 
{
  @include grid-container;
  .row {
    /*empty*/
  }
}
.e-grid-container-full , 
.e-container-full,
.grid-container-full , 
.container-full 
{
  /*empty*/
}