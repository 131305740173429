.header {
  background-color:#FFF;
}

.header__logo {
  display: block;
  margin:2em auto;
  img {
    max-width: 60%;
    margin:0 auto;
    display:block;
  }

}