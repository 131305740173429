// e-btn
.e-btn {
  text-decoration:none;
  padding:10px 20px;
  border-radius:0px;
  position:relative;
  border:1px solid;
  border-radius:8px;
  cursor:pointer;
  box-sizing:border-box;
  transition:.2s;
  display:inline-block;
  color:currentColor;
  &::after {
    // content:(">");
    // margin-left:.5em;
    // transition:.2s;
  }
  &:hover {
    background-color:$branding_black;
    color:$branding_white;
    box-shadow:0px 0px 5px #666;
    &::after {
      margin-left:1em;
    }
  }
  &--no-arrow {
    &::after ,
    &::after 
    {
      display:none;
    }
  }
  &--transparent {
    background-color:transparent;
    color:#222;
    font-weight: 500;
    border:1px solid #666;
    box-sizing:border-box;
    display:inline-block;
  }
  &--brown {
    background-color:$branding_brown;
    color:#FFF;
    font-weight: 500;
    border:0;
    box-sizing:border-box;
    display:inline-block;
  }
  &--big-padding {
    padding-right:60px;
    padding-left:60px;
  }
}