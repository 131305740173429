// GENERAL THEME EXTRAS

button[type="submit"]:disabled {
  opacity: .5;
  cursor: default !important;
}
.user_confirm_timestamp {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}


.flex-wrap-mobile {
  @include mobile {
    flex-wrap:wrap;
  }
}
.e-table {
  border-collapse: collapse;
  tr {
    td {
      padding:8px;
      border:1px solid #EFEFEF;
    }
  }
}
.e-color-primary {
  color:$color_primary;
}
.e-hr-mini {
  background-color:#606060;
  height:1px;
  width:30px
}