.c-form {
  color: $gray;
  a {
    color:#91785B;
    @extend .dim,.link, .b;
  }
  input[type="text"],
  input[type="tel"],
  input[type="number"],
  input[type="email"],
  textarea
  {
    width:100%;
    border-radius:8px;
    padding:10px;
    border:0;
  }
  input[type="checkbox"]{
    border:0;
  }
  label {
    font-weight:500;
    & > span {
      display:block;
      margin-bottom: .4em;
    }
  }
  textarea {
    height: 300px;
    @include mobile {
      height:200px;
    }
  }
}
.c-form__row {
  @extend .row;
  margin-bottom: 1em;
}